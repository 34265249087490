import React from "react"
import Img from "gatsby-image"
import { Flex, Box, Heading } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { BlockRenderer } from "../utils/htmlSerializer"

const TextImagePage = ({ input }) => {
  const direction = input.direction === "imageLeft" ? "row-reverse" : "row"

  return (
    <Box maxW="1380px" mx="auto" my={{ base: "10", xl: "32" }} px="4">
      <Box maxW="5xl" mx="auto" textAlign="center" mb={{ xl: "16" }}>
        <Heading as="h3" fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }}>
          {input.title}
        </Heading>
      </Box>
      <Flex w="full" justify="space-between" direction={{ base: "column", xl: direction }} my="10">
        <Box
          w="full"
          px={{ base: "4", md: "10", xl: "20" }}
          py={{ base: "4", lg: "10" }}
          textAlign={{ base: "center", xl: "left" }}>
          <BlockContent blocks={input._rawText} serializers={{ types: { block: BlockRenderer } }} />
        </Box>
        <Box w="full">
          <Img fluid={input.image.asset.fluid} />
        </Box>
      </Flex>
    </Box>
  )
}

export default TextImagePage
