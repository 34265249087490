import React from "react"
import { Box, CloseButton, Flex, Link, Stack } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { Button } from "."
import { NewsRenderer } from "../sanity/utils/htmlSerializer"
import { internalLink, link } from "../sanity/utils/marks/"

const NewsBanner = ({ input, handleClick, closed }) => {
  if (!input?.released) return null

  return (
    <>
      {closed === "false" && (
        <Box bg="accent" color="white" px={{ base: "4", lg: "8" }} py="6">
          <Flex align={{ lg: "center" }} direction={{ base: "column", lg: "row" }} justify="space-between">
            <Box mr="8">
              <BlockContent
                blocks={input._rawContent}
                serializers={{ types: { block: NewsRenderer }, marks: { internalLink, link } }}
              />
            </Box>
            <Stack spacing={8} direction="row" mt={{ base: 4, lg: 0 }} justify="space-between">
              <Button as={Link} href={input.link} isExternal bg="white" color="accent" borderColor="white">
                {input.title}
              </Button>
              <CloseButton size="lg" onClick={handleClick} />
            </Stack>
          </Flex>
        </Box>
      )}
    </>
  )
}

export default NewsBanner
