import React from "react"
import { Box, Heading, Text } from "@chakra-ui/core"
import { Lottie } from "@crello/react-lottie"
import { Bubble } from "../../components"
import { BlockRenderer } from "../utils/htmlSerializer"
import { motion } from "framer-motion"

import BlockContent from "@sanity/block-content-to-react"
import { internalLink, link } from "../utils/marks/"
import animationData from "../../../static/animation.json"

const Hero = ({ input, context }) => {
  const MotionBox = motion.custom(Box)
  const MotionHeading = motion.custom(Heading)
  const MotionText = motion.custom(Text)

  let titles = [...input.title.split(";")]

  return (
    <Box px="4" position="relative">
      <Box overflow="hidden">
        <Box display={{ base: "block", lg: "flex" }} align="center">
          <Box>
            {context && context.type === "frontPage" ? (
              <>
                <MotionHeading
                  initial={{ opacity: 0, y: "100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ ease: [0.23, 1, 0.32, 1], duration: 2, delay: 0.3 }}
                  fontSize={{ base: "3rem", lg: "5rem", xl: "9.375rem" }}
                  flex="1 0 49%"
                  fontWeight="black"
                  overflow="hidden"
                  lineHeight="1">
                  <MotionText
                    initial={{ opacity: 1, y: 0 }}
                    animate={{ opacity: 0, y: "-100%" }}
                    transition={{
                      ease: [0.23, 1, 0.32, 1],
                      duration: 2,
                      delay: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      repeatDelay: 3,
                    }}>
                    {titles[0]}
                  </MotionText>
                  <MotionText
                    initial={{ opacity: 0, y: "100%" }}
                    animate={{ opacity: 1, y: "-100%" }}
                    transition={{
                      ease: [0.23, 1, 0.32, 1],
                      duration: 2,
                      delay: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      repeatDelay: 3,
                    }}>
                    {titles[1]}
                  </MotionText>
                </MotionHeading>
              </>
            ) : (
              <MotionHeading
                initial={{ opacity: 0, y: "100%" }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: [0.23, 1, 0.32, 1], duration: 2, delay: 0.3 }}
                fontSize={{ base: "3rem", lg: "5rem", xl: "9.375rem" }}
                flex="1 0 49%"
                fontWeight="black"
                overflow="hidden"
                lineHeight="1">
                {input.title}
              </MotionHeading>
            )}
          </Box>
          {input.animation && (
            <Box flex="1 0 49%" display={{ basen: "none", lg: "block" }} position="relative">
              <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: [0.23, 1, 0.32, 1], duration: 3, delay: 0.5 }}>
                <Box h={{ lg: "400px", xl: "600px", position: "absolute", top: 0 }}>
                  <Lottie config={{ animationData: animationData, loop: true }} />
                </Box>
              </MotionBox>
            </Box>
          )}
        </Box>
      </Box>
      <Bubble />
      <Box maxW="3xl" mx="auto" textAlign={{ base: "left", md: "center" }} py={{ base: "16", xl: "20" }}>
        {input._rawBlock && (
          <Box overflow="hidden">
            <MotionBox
              initial={{ opacity: 0, y: "100%" }}
              animate={{ opacity: 1, y: "0" }}
              transition={{ ease: [0.23, 1, 0.32, 1], duration: 1.5, delay: 0.7 }}>
              <BlockContent
                blocks={input._rawBlock}
                serializers={{ types: { block: BlockRenderer }, marks: { internalLink, link } }}
              />
            </MotionBox>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Hero
