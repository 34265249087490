import React from "react"
import { Heading, Text } from "@chakra-ui/core"

export const BlockRenderer = (props) => {
  const style = props.node.style || "normal"

  switch (style) {
    case "h1":
      return (
        <Heading as="h1" fontWeight="black" wordBreak="break-all">
          {props.children}
        </Heading>
      )
    case "h2":
      return (
        <Heading as="h2" fontWeight="bold" fontSize="3xl" wordBreak="break-all">
          {props.children}
        </Heading>
      )
    case "h3":
      return (
        <Heading
          as="h3"
          fontWeight="bold"
          fontSize={{ base: "4xl", xl: "5.625rem" }}
          pb={{ base: 8, xl: 10 }}>
          {props.children}
        </Heading>
      )
    case "h4":
      return (
        <Heading as="h4" fontWeight="bold" fontSize={{ base: "2xl", xl: "5xl" }} pb="4">
          {props.children}
        </Heading>
      )
    case "h5":
      return (
        <Heading as="h5" fontWeight="bold" fontSize={{ base: "xl", xl: "3xl" }}>
          {props.children}
        </Heading>
      )
    case "h6":
      return <Heading as="h6">{props.children}</Heading>
    case "normal":
      return <Text mb="4">{props.children}</Text>
    default:
      return null
  }
}

export const NewsRenderer = (props) => {
  const style = props.node.style || "normal"

  switch (style) {
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return (
        <Heading as="h6" fontWeight="bold" textTransform="none" fontSize={{ base: "lg", xl: "lg" }} pb="2">
          {props.children}
        </Heading>
      )
    case "normal":
      return <Text>{props.children}</Text>
    default:
      return null
  }
}
