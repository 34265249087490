import React from "react"
import Img from "gatsby-image"
import { Flex, Box } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { BlockRenderer } from "../utils/htmlSerializer"

const TextImage = ({ input }) => {
  const direction = input.direction === "imageLeft" ? "row" : "row-reverse"

  return (
    <Flex w="full" justify="space-between" direction={{ base: "column", xl: direction }} my="10">
      <Box
        w="full"
        pr={{ base: 0, xl: input.direction === "imageLeft" ? 10 : null }}
        pl={{ base: 0, xl: input.direction === "imageRight" ? 10 : null }}
        pb={{ base: 10, xl: 0 }}>
        <BlockContent blocks={input._rawText} serializers={{ types: { block: BlockRenderer } }} />
      </Box>
      <Box w="full">
        <Img fluid={input.image.asset.fluid} />
      </Box>
    </Flex>
  )
}

export default TextImage
