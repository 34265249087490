import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Accordion, AccordionItem, AccordionHeader, Flex, Text } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { BlockRenderer } from "../utils/htmlSerializer"
import { Button, SocialShare } from "../../components"
import { Gallery } from "../slices"

const ReferenceTheses = ({ input }) => {
  return (
    <Box bg="background" py={{ base: "10", lg: "24" }}>
      <Box maxW="3xl" color="white" textAlign="center" mx="auto" pb={{ base: 0, xl: 10 }}>
        <Heading
          as="h3"
          fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }}
          lineHeight="1"
          pb={{ base: 0, lg: 8, xl: 16 }}>
          {input.title}
        </Heading>
      </Box>
      <Gallery social input={input.theses} display={{ lg: "none" }} bg="yellow" />
      <Box maxW="1600px" mx="auto" px="4">
        <Accordion color="white" position="relative" display={{ base: "none", lg: "block" }}>
          {input.theses.map((these) => (
            <AccordionItem
              display="grid"
              key={these._id}
              gridTemplateColumns={{ lg: "1fr 1fr", xl: "2fr 1fr" }}
              border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionHeader
                    _expanded={{
                      bg: "yellow",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    _focus={{
                      outline: "1px solid #fefe38",
                    }}
                    textAlign="left"
                    pl="10"
                    py="4">
                    <Flex align="center" direction="row" flex="1 0 100%">
                      <Text fontWeight="black" fontSize="6xl" lineHeight="1" pr="4">
                        {these.number}
                      </Text>
                      <Text fontSize="xl">{these.title}</Text>
                    </Flex>
                  </AccordionHeader>
                  <Box bg="yellow" color="black">
                    <Box display={isExpanded ? "block" : "none"}>
                      <Box position="absolute" top="0" p="10">
                        <Heading as="h4" mb="4">
                          Erläuterung
                        </Heading>
                        <BlockContent
                          blocks={these._rawBody}
                          serializers={{ types: { block: BlockRenderer } }}
                        />

                        <SocialShare title={these.title} />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
        <Flex justify="center" pt="6">
          <Button as={Link} to="/beobachtungen" bg="accent" color="white" title="Alle Beobachtungen öffnen">
            Jetzt bewerten
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default ReferenceTheses
