import React from "react"
import { Link } from "gatsby"
import { Box, Heading, SimpleGrid, Flex, Text, Image } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { Button } from "../../components"
import { BlockRenderer } from "../utils/htmlSerializer"

const ReferenceProcess = ({ input }) => {
  return (
    <Box bg="background" py="24">
      <Box maxW="1600px" mx="auto" px="4">
        <Box maxW="3xl" mx="auto" textAlign="center" color="white" mb="16">
          <Heading as="h3" fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }} pb={{ base: 8, xl: 16 }}>
            {input.title}
          </Heading>
          <BlockContent blocks={input._rawIntro} serializers={{ types: { block: BlockRenderer } }} />

          {/* <Text>{input.intro[1].children[0].text}</Text> */}
        </Box>
        <SimpleGrid columns={{ base: "1", md: "2", xl: "4" }} gap={"8"}>
          {input.process.map((item) => (
            <Flex
              bg="white"
              px="4"
              py="8"
              textAlign="center"
              key={item._id}
              direction="column"
              alignItems="center">
              <Image src={item.icon.asset.url} alt="" size="100px" mb="8" />
              <Heading as="h4" fontSize="2xl" pb="4" wordBreak="break-word" style={{ hyphens: "auto" }}>
                {item.title}
              </Heading>
              <Text
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
                mb="4">
                {item.body[0].children[0].text}
              </Text>
              <Button as={Link} to={`/prozess#${item.title.toLowerCase()}`}>
                Mehr zur Idee erfahren
              </Button>
            </Flex>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default ReferenceProcess
