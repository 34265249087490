import React, { useState, useEffect } from "react"
import { RadioGroup, Box, Flex, Text, Skeleton } from "@chakra-ui/core"
import { Button, RadioButton } from "../../../components"

const PollRequest = ({ input }) => {
  const pollID = input._id

  const [data, setData] = useState({
    data: null,
    loading: true,
    error: false,
  })

  const [state, setState] = useState({
    submitted: (typeof window !== "undefined" && window.localStorage.getItem(`${pollID}`)) || false,
    loading: false,
    error: false,
    checked: false,
  })

  function percentage(partialValue, totalValue) {
    return Math.round((100 * partialValue) / totalValue)
  }

  const fetchData = async () => {
    try {
      const result = await fetch("/.netlify/functions/get-votes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pollID }),
      })

      const json = await result.json()

      setData({ data: json, loading: false, error: false })
    } catch (err) {
      setData({ data: null, loading: false, error: true })
      throw console.error(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function handleSubmit(event) {
    event.preventDefault()

    const formData = new FormData(event.target)
    const answerKey = formData.get("radio-1")

    console.log(new FormData(event.target))

    try {
      // Set state loading to true while fetching the netlify function
      setState({ submitted: false, loading: true, error: false, checked: true })

      await fetch("/.netlify/functions/vote-for-answer", {
        method: "POST",
        body: JSON.stringify({ pollID, answerKey }),
      })
        .then((res) => res.json())
        .catch((err) => console.error(err))

      // Set localStorage
      localStorage.setItem(`${pollID}`, true)

      fetchData()

      // Set state submitted to localStorage
      setState({ submitted: localStorage.getItem(`${pollID}`), loading: false, error: false, checked: true })
    } catch (err) {
      // Fallback if something went wrong
      setState({ submitted: false, loading: false, error: true, checked: false })
      throw console.error(err)
    }
  }

  // Render while loading is true
  if (state.loading) {
    return (
      <Button isLoading loadingText="Wird abgeschickt">
        Stimme abgeben
      </Button>
    )
  }

  // Render if error is true
  if (state.error) {
    return <h2>Uh oh! Something went wrong – please try again later :/</h2>
  }

  // Render if submitted is true
  if (state.submitted) {
    const allVotes = []

    const reduceVotes = (int) => {
      return int.reduce((a, b) => a + b, 0)
    }

    const pushVotes = () => {
      if (data.data !== null) {
        data.data[0].answers.map((answer) => allVotes.push(answer.votes))
      }
      return null
    }

    pushVotes()

    return (
      <Flex direction={{ base: "column", lg: "row" }} justify={{ lg: "space-between" }}>
        {data.loading ? (
          <Skeleton height="100px" />
        ) : (
          data.data !== null &&
          data.data[0].answers.map((answer) => {
            return (
              <Box textAlign="center" key={answer._key} pb={{ base: 6, xl: 0 }}>
                <Text fontWeight="bold" fontSize="2xl">
                  {percentage(answer.votes, reduceVotes(allVotes))}%
                </Text>
                <Text color="#757677" fontWeight="medium">
                  (<span key={answer._key}>{answer.votes}</span> Stimmen)
                </Text>
                <Text fontSize="xl" fontWeight="bold">
                  {answer.answer}
                </Text>
              </Box>
            )
          })
        )}
      </Flex>
    )
  }

  // Default render
  return (
    <form onSubmit={handleSubmit}>
      <RadioGroup
        isFullwidth
        display={{ md: "flex" }}
        isInline
        spacing={0}
        justifyContent={{ md: "space-between" }}
        alignItems="center"
        name="radio-1"
        onChange={() => console.log("work")}>
        {input.answers.map((answer) => (
          <RadioButton
            value={answer._key}
            key={answer._key}
            onClick={() => setState({ ...state, checked: true })}>
            {answer.answer}
          </RadioButton>
        ))}
      </RadioGroup>
      <Box w="full" textAlign="center" pt={{ base: "6", lg: "12" }}>
        <Button
          bg="background"
          color="white"
          type="submit"
          isLoading={state.loading}
          isDisabled={!state.checked}
          loadingText="Stimme wird abgegeben ...">
          Stimme abgeben
        </Button>
      </Box>
    </form>
  )
}

export default PollRequest
