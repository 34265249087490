import React from "react"
import { Box, VisuallyHidden, ControlBox } from "@chakra-ui/core"
import { FaCheck } from "react-icons/fa"

const RadioButton = ({ children, value, name = "radio-1", onClick }) => {
  return (
    <Box
      as="label"
      display="inline-flex"
      verticalAlign="top"
      alignItems="center"
      cursor="pointer"
      mb={{ base: 4, xl: 0 }}
      onClick={onClick}>
      <VisuallyHidden type="radio" as="input" value={value} name={name} />
      <ControlBox
        size="40px"
        bg="white"
        border="2px"
        rounded="2px"
        type="radio"
        borderColor="red"
        _focus={{ boxShadow: "outline" }}
        _hover={{ borderColor: "gray.300" }}
        _disabled={{ opacity: "40%" }}
        _checked={{ bg: "white", borderColor: "backround" }}>
        <Box as={FaCheck} size="24px" />
      </ControlBox>
      <Box fontWeight="bold" ml="2" fontSize="lg" userSelect="none">
        {children}
      </Box>
    </Box>
  )
}

export default RadioButton
