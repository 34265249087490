import React from "react"
import { Box } from "@chakra-ui/core"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const Bubble = ({ right, bottom, ...props }) => {
  const MotionBox = motion.custom(Box)

  return (
    <InView>
      {({ inView, ref }) => (
        <MotionBox
          ref={ref}
          initial={{ scale: 0.3, opacity: 0 }}
          animate={{ scale: inView ? 1 : 0.3, opacity: inView ? 1 : 0 }}
          transition={{ ease: [0.23, 1, 0.32, 1], duration: 2 }}
          position="absolute"
          size={{ base: "495px", md: "950px", lg: "1200px", xl: "1400px" }}
          bg="yellow"
          bottom={{
            base: bottom && "-120px",
            md: bottom && "-350px",
            lg: bottom && "-400px",
            xl: bottom && "-600px",
          }}
          top={{
            base: bottom ? "unset" : "-120px",
            md: bottom ? "unset" : "-350px",
            lg: bottom ? "unset" : "-400px",
            xl: bottom ? "unset" : "-600px",
          }}
          left={{
            base: right ? "120px" : "-120px",
            md: right ? "350px" : "-350px",
            lg: right ? "400px" : "-400px",
            xl: right ? "500px" : "-500px",
          }}
          rounded="full"
          zIndex="hide"
          {...props}
        />
      )}
    </InView>
  )
}

export default Bubble
