import React from "react"
import { Button as ThemeButton } from "@chakra-ui/core"

const Button = ({ bg = "yellow", children, ...props }) => {
  return (
    <ThemeButton
      rounded="3px"
      px="8"
      py="3"
      fontWeight="black"
      lineHeight="0"
      bg={bg}
      color={bg === "yellow" ? "black" : "white"}
      border={
        bg === "yellow"
          ? "2px solid #feff00"
          : bg === "background"
          ? "2px solid #343434"
          : "2px solid #fd1897"
      }
      _disabled={{
        opacity: 0.4,
        cursor: "not-allowed",
      }}
      _hover={{
        bg: bg === "yellow" ? "background" : "yellow",
        color: bg === "yellow" ? "white" : "background",
        border: bg === "yellow" ? "2px solid #343434" : "2px solid #feff00",
        textDecoration: "none",
      }}
      _active={{
        bg: bg === "yellow" ? "background" : "yellow",
        color: bg === "yellow" ? "white" : "background",
        border: bg === "yellow" ? "2px solid #343434" : "2px solid #feff00",
      }}
      {...props}>
      {children}
    </ThemeButton>
  )
}

export default Button
