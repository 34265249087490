import React, { useState, useEffect } from "react"
import { Link as InternLink, useStaticQuery, graphql } from "gatsby"
import { Flex, Link, Box } from "@chakra-ui/core"
import Headroom from "react-headroom"

import MenuItems from "./MenuItems"
import { Logo, BurgerOpen } from "../ui/"
import { NewsBanner } from ".."
import { useStateWithLocalStorage } from "./useLocalStorage"

const Navigation = (props) => {
  const data = useStaticQuery(graphql`
    query News {
      news: allSanityFrontPage {
        nodes {
          _updatedAt
          _id
          news {
            link
            title
            released
            _rawContent(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  `)

  const { news } = data.news.nodes[0]._id === "frontPage" ? data.news.nodes[0] : data.news.nodes[1]
  const { _updatedAt } = data.news.nodes[0]._id === "frontPage" ? data.news.nodes[0] : data.news.nodes[1]

  const [show, setShow] = useState(false)

  const [closed, setClosed] = useStateWithLocalStorage(`${_updatedAt}`)

  useEffect(() => {
    closed === "" && setClosed("false")
  }, [closed, setClosed])

  Object.entries(typeof window !== "undefined" && window.localStorage).map((item) => {
    let date = new Date()
    let expireDays = 30
    let expireDate = date.setDate(date.getDate() + expireDays)

    if (Date.parse(item) > expireDate) {
      localStorage.removeItem(item)
    }
    return null
  })

  const closeOnClick = () => setClosed("true")
  const openOnClick = () => setClosed("false")

  return (
    <Headroom disableInlineStyles className={show ? "menu-open" : ""}>
      <NewsBanner input={news} handleClick={closeOnClick} closed={closed} />
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        py="3"
        px={{ base: "4", lg: "8" }}
        w="full"
        zIndex="sticky"
        pos="relative"
        top="0"
        {...props}>
        <Flex align="center" zIndex="sticky">
          <Link
            as={InternLink}
            to="/"
            _focus={{
              outline: "none",
            }}>
            <Logo />
          </Link>
        </Flex>

        <Box display={{ base: "block", lg: "none" }} zIndex="sticky" onClick={() => setShow(!show)}>
          <BurgerOpen />
        </Box>

        <MenuItems show={show} handleClick={openOnClick} closed={closed} />

        <Box
          width="100%"
          height="100vh"
          position="absolute"
          left="0"
          top="0"
          background="yellow"
          opacity="0.7"
          zIndex="hidden"
          display={show ? "block" : "none"}
          style={{
            backdropFilter: "blur(10px)",
          }}
          onClick={() => setShow(!show)}
        />
      </Flex>
    </Headroom>
  )
}

export default Navigation
