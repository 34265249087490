import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import { BlockRenderer } from "../utils/htmlSerializer"

const BlockText = ({ input }) => {
  return <BlockContent blocks={input._rawBlock} serializers={{ types: { block: BlockRenderer } }} />
}

export default BlockText
