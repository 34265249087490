import React from "react"
import { Flex } from "@chakra-ui/core"
import {
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share"
import { FaFacebook, FaTwitter, FaLinkedinIn, FaTelegramPlane, FaWhatsapp, FaEnvelope } from "react-icons/fa"

const SocialShare = ({ title }) => {
  return (
    <Flex mt={8}>
      <TwitterShareButton
        url="https://www.hh-konvent.de/"
        title={`${title} Was haltet ihr davon? #hhkonvent`}
        style={{ marginRight: 10 }}>
        <FaTwitter size={32} color="black" />
      </TwitterShareButton>
      <FacebookShareButton
        url="https://www.hh-konvent.de/"
        quote={`${title} Was haltet ihr davon? #hhkonvent`}
        style={{ marginRight: 10 }}>
        <FaFacebook size={32} color="black" />
      </FacebookShareButton>
      <LinkedinShareButton
        url="https://www.hh-konvent.de/"
        title="Hamburg Konvent"
        summary={`${title} Was haltet ihr davon? #hhkonvent`}
        source="https://hamburg-convent.de/"
        style={{ marginRight: 10 }}>
        <FaLinkedinIn size={32} color="black" />
      </LinkedinShareButton>
      <TelegramShareButton
        url="https://www.hh-konvent.de/"
        title={`${title} Was haltet ihr davon? #hhkonvent`}
        style={{ marginRight: 10 }}>
        <FaTelegramPlane size={32} color="black" />
      </TelegramShareButton>
      <WhatsappShareButton
        url="https://www.hh-konvent.de/"
        title={`${title} Was haltet ihr davon? #hhkonvent`}
        style={{ marginRight: 10 }}>
        <FaWhatsapp size={32} color="black" />
      </WhatsappShareButton>
      <EmailShareButton
        url="https://www.hh-konvent.de/"
        subject="Hamburg Konvent These"
        body={`${title} Was haltet ihr davon? #hhkonvent`}>
        <FaEnvelope size={32} color="black" />
      </EmailShareButton>
    </Flex>
  )
}

export default SocialShare
