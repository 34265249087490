import React from "react"
import { Link as InternLink } from "gatsby"
import { Box, Flex, Link } from "@chakra-ui/core"
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"

import MenuItems from "./navigation/MenuItems"
import { Logo } from "./"

const Footer = () => {
  return (
    <Box as="footer" p="10" bg="background" color="white">
      <Flex justify="space-between" align="center" pb="10" direction={{ base: "column", lg: "row" }}>
        <Flex align="center" direction={{ base: "column", lg: "row" }}>
          <Box>
            <Link as={InternLink} to="/diskurs">
              <Logo fill="white" />
            </Link>
          </Box>
          <Flex ml={{ base: "0", lg: "8" }} pt={{ base: "6", lg: "0" }}>
            <Link href="https://www.facebook.com/hashtag/hhkonvent" isExternal mr="4">
              <FaFacebook size="2em" />
            </Link>
            <Link href="https://twitter.com/search?q=%23hhkonvent&src=typed_query" isExternal mr="4">
              <FaTwitter size="2em" />
            </Link>
            <Link href="https://www.instagram.com/explore/tags/hhkonvent/" isExternal mr="4">
              <FaInstagram size="2em" />
            </Link>
            {/* <Link href="https://xing.com/" isExternal mr="4">
              <FaXingSquare size="2em" />
            </Link>
            <Link href="https://linkedin.com/" isExternal>
              <FaLinkedin size="2em" />
            </Link> */}
          </Flex>
        </Flex>
        <MenuItems zIndex="base" textAlign="center" footer />
      </Flex>
      <Flex justify="space-between" align="center" fontSize="sm" direction={{ base: "column", lg: "row" }}>
        <Box>© {new Date().getFullYear()} Hamburg Konvent</Box>
        <Flex>
          <Link as={InternLink} to="/impressum" mt={{ base: 4, lg: 0 }} mr="6" display="block">
            Impressum
          </Link>
          <Link as={InternLink} to="/" mt={{ base: 4, lg: 0 }} mr={{ base: 0, lg: 6 }} display="block">
            Datenschutz
          </Link>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Footer
