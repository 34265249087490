import React from "react"

import {
  BlockText,
  Card,
  Gallery,
  Hero,
  Iframe,
  Image,
  Outro,
  ReferencePosts,
  ReferenceProcess,
  ReferenceTheses,
  TextImage,
  TextImagePage,
  Theses,
  XingEvent,
  Poll,
} from "../sanity/slices"

const Slices = ({ input, context }) => {
  const slice = input.map((slice) => {
    switch (slice._type) {
      case "bodyBlock":
        return <BlockText input={slice} key={slice._key} />
      case "card":
        return <Card input={slice} key={slice._key} />
      case "gallery":
        return <Gallery input={slice} key={slice._key} />
      case "hero":
        return <Hero input={slice} key={slice._key} context={context} />
      case "iframe":
        return <Iframe input={slice} key={slice._key} />
      case "fullImage":
        return <Image input={slice} key={slice._key} />
      case "outro":
        return <Outro input={slice} key={slice._key} />
      case "process":
        return <Card input={slice} key={slice._id} />
      case "referencePosts":
        return <ReferencePosts input={slice} key={slice._key} />
      case "referenceProcess":
        return <ReferenceProcess input={slice} key={slice._key} />
      case "referenceTheses":
        return <ReferenceTheses input={slice} key={slice._key} />
      case "textImage":
        return <TextImage input={slice} key={slice._key} />
      case "textImagePage":
        return <TextImagePage input={slice} key={slice._key} />
      case "theses":
        return <Theses input={slice} key={slice._id} />
      case "xingEvent":
        return <XingEvent input={slice} key={slice._key} />
      case "poll":
        return <Poll input={slice} key={slice._id} />
      default:
        return null
    }
  })
  return <>{slice}</>
}

export default Slices
