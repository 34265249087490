import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { Box } from "@chakra-ui/core"

import { BlockRenderer } from "../../utils/htmlSerializer"
import PollRequest from "./PollRequest"

const Poll = ({ input }) => {
  return (
    <Box p={{ base: 4, xl: 12 }} bg="yellow" my={{ base: 4, xl: 12 }}>
      <Box pb={{ base: 4, xl: 12 }}>
        <BlockContent blocks={input._rawIntro} serializers={{ types: { block: BlockRenderer } }} />
      </Box>
      <PollRequest input={input} />
    </Box>
  )
}

export default Poll
