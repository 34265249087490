import React from "react"
import { Link as InternLink } from "gatsby"
import { Link } from "@chakra-ui/core"
import { Button } from "../../../components"

const internalLink = (props) => {
  const ref = props.mark.reference
  const slug = ref._type === "post" ? "/diskurs/" : "/"

  if (props.mark.button) {
    return (
      <Button as={InternLink} to={`${slug}${props.mark.reference.slug.current}`} my="8">
        {props.children}
      </Button>
    )
  }
  return (
    <Link as={InternLink} to={`${slug}${props.mark.reference.slug.current}`} fontWeight="bold">
      {props.children}
    </Link>
  )
}

export default internalLink
