import React from "react"
import PropTypes from "prop-types"

import { Contact, Navigation, Footer } from "./"
import "./global.css"

const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      <main style={{ marginTop: "40px" }}>{children}</main>
      <Contact />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
