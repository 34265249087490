import React from "react"
import { Box, Heading } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

import { BlockRenderer } from "../utils/htmlSerializer"
import { SocialShare } from "../../components"

const Theses = ({ input }) => {
  const MotionBox = motion.custom(Box)

  return (
    <InView threshold={0.2} triggerOnce>
      {({ inView, ref }) => (
        <Box
          ref={ref}
          bg="background"
          py={{ base: "8", lg: "10" }}
          pl={{ base: "4", lg: "8" }}
          pr={{ base: "6", lg: "10" }}>
          <MotionBox
            initial={{ opacity: 0, y: "60%" }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : "60%" }}
            transition={{ ease: [0.23, 1, 0.32, 1], duration: 1.5 }}
            bg="white"
            maxW="1600px"
            py={{ base: "8", lg: "16" }}
            px={{ base: "4", lg: "32" }}
            mx="auto"
            boxShadow="8px 8px 0 0px yellow">
            <Heading
              as="h5"
              mb="10"
              fontWeight="black"
              fontSize={{ base: "xl", xl: "3xl" }}
              textTransform={{ base: "none", xl: "uppercase" }}>
              {input.number}. {input.title}
            </Heading>
            <BlockContent blocks={input._rawBody} serializers={{ types: { block: BlockRenderer } }} />
            <SocialShare title={input.title} />
          </MotionBox>
        </Box>
      )}
    </InView>
  )
}

export default Theses
