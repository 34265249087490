import React from "react"
import { AspectRatioBox, Box } from "@chakra-ui/core"

const Iframe = ({ input }) => {
  return (
    <>
      <AspectRatioBox
        display={{ base: "none", lg: "block" }}
        maxW="972px"
        ratio={6 / 1}
        my={{ base: "8", lg: "10" }}>
        <Box as="iframe" src={input ? input.link : "https://anchor.fm/hamburg-konvent/embed"} alt=""></Box>
      </AspectRatioBox>
      <AspectRatioBox display={{ lg: "none" }} maxW="972px" ratio={23 / 5} my={{ base: "8", lg: "10" }}>
        <Box as="iframe" src={input ? input.link : "https://anchor.fm/hamburg-konvent/embed"} alt=""></Box>
      </AspectRatioBox>
    </>
  )
}

export default Iframe
