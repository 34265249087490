import React from "react"
import { Box, Flex, Image, Heading } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

import { BlockRenderer } from "../utils/htmlSerializer"

const Card = ({ input }) => {
  const MotionBox = motion.custom(Box)

  return (
    <InView threshold={0} triggerOnce>
      {({ inView, ref }) => (
        <Box
          ref={ref}
          bg="background"
          py={{ base: "8", lg: "10" }}
          pl={{ base: "4", lg: "8" }}
          pr={{ base: "6", lg: "10" }}>
          <MotionBox
            bg="white"
            maxW="1600px"
            py={{ base: "8", lg: "16" }}
            px={{ base: "4", md: "16", lg: "32" }}
            mx="auto"
            boxShadow="8px 8px 0 0px yellow"
            initial={{ opacity: 0, y: "60%" }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : "60%" }}
            transition={{ ease: [0.23, 1, 0.32, 1], duration: 1.5 }}>
            <Box id={input.title.toLowerCase()} />
            {input.icon && (
              <Flex justifyContent="center" pb="8">
                <Image src={input.icon.asset.url} alt="" size="100px" />
              </Flex>
            )}
            <Heading textAlign="center" as="h5" mb="10" fontWeight="black" fontSize="2xl">
              {input.title}
            </Heading>
            <BlockContent
              blocks={input._rawBlock ? input._rawBlock : input._rawBody}
              serializers={{ types: { block: BlockRenderer } }}
            />
          </MotionBox>
        </Box>
      )}
    </InView>
  )
}

export default Card
