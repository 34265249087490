import React from "react"

const BurgerOpen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38.68" height="27.19" viewBox="0 0 38.68 27.19">
      <g transform="translate(-296.086 -32.887)">
        <path
          d="M0,0H33.33"
          transform="translate(298.778 58.077)"
          fill="none"
          stroke="#262626"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M4283.09,49.536h18.821l4.524,2.472,4.027-2.472h7.308"
          transform="translate(-3985.004 -0.522)"
          fill="none"
          stroke="#262626"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M4321.991,26.1a26.138,26.138,0,0,0-16.333-6.215,24.616,24.616,0,0,0-16.037,6.215"
          transform="translate(-3990.372 15)"
          fill="none"
          stroke="#262626"
          strokeLinecap="round"
          strokeWidth="4"
        />
      </g>
    </svg>
  )
}

export default BurgerOpen
