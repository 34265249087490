import React from "react"
import Img from "gatsby-image"
import { Box, Text } from "@chakra-ui/core"

const Image = ({ input }) => {
  return (
    <Box my={{ base: 4, xl: 12 }}>
      <Img fluid={input.image.asset.fluid} />
      {input.caption && (
        <Text mt="3" fontSize="sm" color="grey">
          {input.caption}
        </Text>
      )}
    </Box>
  )
}

export default Image
