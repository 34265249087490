import React from "react"
import { Link as InternLink } from "gatsby"

import { Box, IconButton } from "@chakra-ui/core"
import { Button } from ".."

const MenuItems = ({ show, footer, handleClick, closed, ...props }) => {
  return (
    <Box
      display={{ base: show ? "block" : "none", lg: "flex" }}
      position={{ base: show ? "absolute" : "relative" }}
      top={{ base: "60px", lg: 0 }}
      right={{ base: "20px", lg: "unset" }}
      width={{ base: "full", lg: "auto" }}
      alignItems="center"
      textAlign={{ base: "right", lg: "left" }}
      zIndex="sticky"
      fontSize="lg"
      {...props}>
      {!footer && (
        <Box mt={{ base: 6, lg: 0 }} mr={{ base: 0, lg: 6 }}>
          <Button
            bg={{ base: "background", lg: "yellow" }}
            borderColor={{ base: "background", lg: "yellow" }}
            color={{ base: "white", lg: "background" }}
            as={InternLink}
            to={`/newsletter`}
            activeStyle={{ fontWeight: "700" }}>
            Newsletter abonnieren
          </Button>
        </Box>
      )}
      <Box mt={{ base: 6, lg: 0 }} mr={{ base: 0, lg: 6 }} fontWeight={{ base: "bold", lg: "medium" }}>
        <InternLink to={`/beobachtungen`} activeStyle={{ fontWeight: "700" }}>
          Beobachtungen
        </InternLink>
      </Box>
      <Box mt={{ base: 6, lg: 0 }} mr={{ base: 0, lg: 6 }} fontWeight={{ base: "bold", lg: "medium" }}>
        <InternLink to={`/diskurs`} activeStyle={{ fontWeight: "700" }}>
          Diskurs
        </InternLink>
      </Box>
      <Box mt={{ base: 6, lg: 0 }} mr={{ base: 0, lg: 6 }} fontWeight={{ base: "bold", lg: "medium" }}>
        <InternLink to={`/prozess`} activeStyle={{ fontWeight: "700" }}>
          Prozess
        </InternLink>
      </Box>
      <Box mt={{ base: 6, lg: 0 }} mr={{ base: 0, lg: 6 }} fontWeight={{ base: "bold", lg: "medium" }}>
        <InternLink to={`/event`} activeStyle={{ fontWeight: "700" }}>
          Event
        </InternLink>
      </Box>
      {!footer && (
        <Box mt={{ base: 6, lg: 0 }}>
          <IconButton
            icon="bell"
            aria-label="Open news"
            color="accent"
            bg="gray.100"
            onClick={handleClick}
            isDisabled={closed === "false" ? true : false}
          />
        </Box>
      )}
    </Box>
  )
}

export default MenuItems
