import React, { useState } from "react"
import { Box, IconButton, Text, Flex, Heading } from "@chakra-ui/core"
import Img from "gatsby-image"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import BlockContent from "@sanity/block-content-to-react"
import { motion } from "framer-motion"

import { BlockRenderer } from "../utils/htmlSerializer"
import { Bubble, SocialShare } from "../../components"

const Gallery = ({ input, social, ...props }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const data = input.person || input

  const totalSlides = data.length

  const prev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    } else {
      setCurrentPage(totalSlides - 1)
    }
  }

  const next = () => {
    if (currentPage < data.length - 1) {
      setCurrentPage(currentPage + 1)
    } else {
      setCurrentPage(0)
    }
  }

  return (
    <Box maxW="1380px" mx="auto" position="relative" {...props} my={{ base: "10", xl: "32" }}>
      <Box maxW="5xl" mx="auto" textAlign="center" px="4" mb="10">
        {input.title && (
          <Heading
            as="h3"
            fontSize={{ base: "3xl", lg: "6xl", xl: "5.625rem" }}
            pb={{ base: 8, xl: 16 }}
            hyphens="auto">
            {input.title}
          </Heading>
        )}
        <BlockContent blocks={input._rawIntro} serializers={{ types: { block: BlockRenderer } }} />
      </Box>
      <Flex direction={{ base: "column", lg: input.direction === "right" ? "row-reverse" : "row" }}>
        <Box flex="1 0 49%">
          {data.map((item, i) => {
            const isCurrentPage = currentPage === i

            if (!isCurrentPage) return null
            return (
              <Box key={i} overflow="hidden">
                {item.image ? (
                  <motion.div
                    initial={{ scale: 1.2 }}
                    animate={{ scale: 1 }}
                    transition={{ ease: "easeOut", duration: 1 }}>
                    <Img fluid={{ ...item.image.asset.fluid, aspectRatio: 1 }} />
                  </motion.div>
                ) : (
                  <Box bg="background"></Box>
                )}
              </Box>
            )
          })}

          <Flex
            alignItems="center"
            justifyContent={input.direction === "right" ? "flex-start" : "flex-end"}
            pl={{ base: input.direction === "right" ? "4" : 0, xl: 0 }}
            bg={social ? "background" : null}
            flex="1 0 49%">
            <Box>
              <Text
                as="span"
                fontWeight="bold"
                fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                color={social ? "white" : null}
                pr="6">
                {currentPage + 1}/{totalSlides}
              </Text>
            </Box>
            <IconButton
              onClick={prev}
              icon={FaChevronLeft}
              fontSize={{ base: "lg", md: "xl", lg: "4xl" }}
              height="16"
              width="16"
              bg="white"
              rounded="none"
              aria-label="Vorheriges Bild"
              _focus={{
                outline: "1px solid #19202d",
              }}
              _hover={{
                bg: "#FFFF8F",
              }}></IconButton>
            <IconButton
              onClick={next}
              icon={FaChevronRight}
              fontSize={{ base: "lg", md: "xl", lg: "4xl" }}
              height="16"
              width="16"
              bg="yellow"
              rounded="0"
              aria-label="Nächstes Bild"
              _focus={{
                outline: "1px solid #19202d",
              }}
              _hover={{
                bg: "#FFFF8F",
              }}></IconButton>
          </Flex>
        </Box>
        <Box>
          {data.map((item, i) => {
            const isCurrentPage = currentPage === i

            if (!isCurrentPage) return null
            return (
              <Box key={i} px={{ base: "4", md: "10", xl: "20" }} py={{ base: "4", lg: "10" }}>
                <Text
                  fontWeight="bold"
                  lineHeight="1.3"
                  pb={{ base: "6", md: "8", lg: "10" }}
                  fontSize={{ base: item.name ? "4xl" : "2xl", xl: "5xl" }}>
                  {item.name ? item.name : item.title}
                </Text>
                <BlockContent
                  blocks={item._rawBio ? item._rawBio : item._rawBody}
                  serializers={{ types: { block: BlockRenderer } }}
                />

                {social && <SocialShare title={item.title} />}
              </Box>
            )
          })}
        </Box>
      </Flex>
      {input.direction === "left" && <Bubble bottom d={{ base: social ? "none" : "block", xl: "block" }} />}
    </Box>
  )
}

export default Gallery
