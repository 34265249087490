import React from "react"
import { Avatar, Box, Heading, Flex } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"
import { BlockRenderer } from "../sanity/utils/htmlSerializer"
import { internalLink, link } from "../sanity/utils/marks/"

const Author = ({ input }) => {
  const profileImage = input.thumbnail && input.thumbnail.asset.url
  return (
    <Box maxW="6xl" mx="auto" bg="gray.100" p="8" mb={{ base: "10", xl: "24" }}>
      <Flex align={{ base: "flex-start", lg: "center" }} direction={{ base: "column", lg: "row" }}>
        <Avatar size="xl" name={input.name} src={profileImage} mb={{ base: "4", lg: 0 }} mr={{ lg: 8 }} />
        <Box>
          <Heading as="h5" fontSize="xl" fontWeight="normal" color="gray.500">
            Verfasst von
          </Heading>
          <Heading>{input.name}</Heading>
          <BlockContent
            blocks={input._rawBio}
            serializers={{ types: { block: BlockRenderer }, marks: { internalLink, link } }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default Author
