import React from "react"
import { Box } from "@chakra-ui/core"

const XingEvent = ({ input }) => {
  if (!input) return null
  return (
    <Box w="full" px="4" maxW="1600px" mx="auto" py={{ base: "10", xl: "32" }}>
      <iframe title={input.id} src={input.url} id={input.id} width="100%" frameBorder="0" />
    </Box>
  )
}

export default XingEvent
