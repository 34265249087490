import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Heading, Text, Link } from "@chakra-ui/core"

import { Button } from "."
import background from "../../static/newsletter-background.svg"

const Contact = () => {
  return (
    <Box
      className="animation-gradient"
      // background="linear-gradient(320deg, rgba(253,23,140,1) 0%, rgba(255,251,56,1) 100%);"
      textAlign={{ base: "left", md: "center" }}
      pb="20"
      px="4">
      <Box
        background={`url('${background}') no-repeat bottom center`}
        pos="relative"
        bgSize="cover"
        maxW="870px"
        m="0 auto"
        mb="8"
        pb={{ base: "76vw", md: "58%", xl: "33%" }}>
        <Box pos="absolute" top="36%" left="50%" transform="translate(-50%, -50%)" textAlign="center">
          <Heading mb={{ base: "4", md: "10" }} fontSize={{ base: "4xl", md: "5xl" }}>
            Newsletter
          </Heading>
          <Text fontWeight="medium" fontSize={{ base: "xl", md: "2xl" }} mb={{ base: "4", md: "10" }}>
            Immer auf dem aktuellen Stand bleiben
          </Text>
          <Button as={GatsbyLink} to="/newsletter" bg="background">
            Jetzt anmelden
          </Button>
        </Box>
      </Box>
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }}
        pb={{ base: 8, xl: 16 }}>
        Kontakt
      </Heading>
      <Text fontWeight="medium" fontSize={{ base: "xl", xl: "2xl" }}>
        <Text as="span" fontWeight="bold">
          Hamburg Konvent
        </Text>
        <br />
        c/o ZEIT-Stiftung Ebelin und Gerd Bucerius
        <br />
        Feldbrunnenstraße 56
        <br />
        20148 Hamburg
        <br />
      </Text>
      <Text fontWeight="medium" fontSize={{ base: "xl", xl: "2xl" }} mt="8">
        <Link href="mailto:info@hh-konvent.de">info@hh-konvent.de</Link>
        <br />
        <Link href="tel:+4940413366">040 413366</Link>
      </Text>
    </Box>
  )
}

export default Contact
