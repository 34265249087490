import React from "react"
import { Box, Heading } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"

import { BlockRenderer } from "../utils/htmlSerializer"
import { internalLink, link } from "../utils/marks/"

const Outro = ({ input }) => {
  return (
    <Box bg="background" color="white" py={{ base: "10", xl: "20" }}>
      <Box maxW="3xl" mx="auto" textAlign={{ base: "left", xl: "center" }} px="4">
        <Heading
          as="h3"
          fontWeight="bold"
          fontSize={{ base: "4xl", lg: "6xl", xl: "5.625rem" }}
          pb={{ base: 8, xl: 10 }}
          wordBreak="break-all">
          {input.title}
        </Heading>
        <BlockContent
          blocks={input._rawBlock}
          serializers={{ types: { block: BlockRenderer }, marks: { internalLink, link } }}
        />
      </Box>
    </Box>
  )
}

export default Outro
