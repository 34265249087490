import React from "react"
import { Link } from "@chakra-ui/core"
import { Button } from "../../../components"

const link = (props, rest) => {
  if (props.mark.button) {
    return (
      <Button as={Link} href={props.mark.url} isExternal>
        {props.children}
      </Button>
    )
  }

  return (
    <Link href={props.mark.url} isExternal fontWeight="bold">
      {props.children}
    </Link>
  )
}

export default link
