import { useState, useEffect } from "react"

export const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = useState(
    (typeof window !== "undefined" && window.localStorage.getItem(localStorageKey)) || ""
  )

  useEffect(() => {
    localStorage.setItem(localStorageKey, value)
  }, [value])

  return [value, setValue]
}
